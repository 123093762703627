<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            name="name"
            v-model.trim="form.name"
            required
          />
        </div>
      </div>
      <div class="col-12 col-xl" v-if="!promotion || !form.src">
        <div class="form-group">
          <label for="file">Datei</label>
          <input
            type="file"
            class="form-control-file"
            name="file"
            accept="image/*, video/mp4, video/webm"
            @change="fileChange($event)"
            required
          />
        </div>
      </div>
      <div class="col-12 col-xl-2" v-if="form.duration">
        <div class="form-group">
          <label for="duration">Anzeigedauer</label>
          <input
            type="number"
            min="5"
            class="form-control"
            name="duration"
            v-model.number="form.duration"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="progress mb-3" v-if="uploading">
          <div class="progress-bar bg-dark" role="progressbar" ref="progress">
            {{ uploadProgress }} %
          </div>
        </div>
      </div>
      <div class="col-12" v-if="form.src">
        <div class="form-group">
          <a :href="form.src" target="_blank" class="mb-3"
            >Promotion anzeigen</a
          >
        </div>
      </div>
      <div class="col-12">
        <h6>Studios</h6>
      </div>
      <div class="col-12">
        <div
          class="form-group form-check"
          v-for="studio in studios"
          :key="studio.id"
        >
          <input
            type="checkbox"
            class="form-check-input"
            :id="studio.id"
            v-model="form.studios"
            :value="studio.id"
          />
          <label class="form-check-label" :for="studio.id">{{
            studio.name
          }}</label>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">
      Speichern
    </button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{ errMsg }}</div>
  </form>
</template>

<script>
import { firestore, storage } from '../firebase';
import { v4 as uuid } from 'uuid';

export default {
  data() {
    return {
      promotion: null,
      form: {
        name: '',
        studios: []
      },
      submitting: false,
      studios: [],
      uploading: false,
      uploadProgress: 0,
      errMsg: ''
    };
  },
  async created() {
    const id = this.$route.params.id;

    const studiosSnapshot = await firestore
      .collection('studios')
      .orderBy('nr')
      .get();

    this.studios = studiosSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    if (id) {
      const promotionSnapshot = await firestore
        .collection('promotions')
        .doc(id)
        .get();

      this.promotion = {
        id: promotionSnapshot.id,
        ...promotionSnapshot.data()
      };
      this.form = { ...this.promotion };

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.promotion) {
          await firestore
            .collection('promotions')
            .doc(this.promotion.id)
            .update({
              ...this.form
            });
        } else {
          await firestore.collection('promotions').add({
            ...this.form
          });
        }

        this.$router.push({
          name: 'promotions'
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    },
    async fileChange(event) {
      if (event.target.files.length) {
        const file = event.target.files[0];

        if (file.type.startsWith('image')) {
          this.form = {
            ...this.form,
            duration: 5
          };
        }

        const storageRef = storage.ref();

        this.uploading = true;

        const filename = uuid() + '.' + file.name.split('.').pop();

        this.form = {
          ...this.form,
          type: file.type,
          filename
        };

        const uploadTask = storageRef
          .child(`promotions/${filename}`)
          .put(file, {
            cacheControl: 'public, max-age=30672000'
          });

        uploadTask.on(
          'state_changed',
          snapshot => {
            this.uploadProgress = Math.ceil(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          () => {
            this.uploading = false;
          },
          async () => {
            try {
              const src = await uploadTask.snapshot.ref.getDownloadURL();

              this.form = {
                ...this.form,
                src
              };
            } catch (err) {
              this.errMsg(err.message);

              throw err;
            } finally {
              this.uploading = false;
            }
          }
        );
      }
    }
  },
  watch: {
    uploadProgress(val) {
      this.$refs.progress.style.width = `${val}%`;
    }
  }
};
</script>
